import React from 'react'
import { Card } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Rating from './Rating'
import '../assets/styles/index.css'

const Product = ({product}) => {
  return (
    <Card className = "my-2 p-0 rounded bgColor interCard ">
      <Link to={`/product/${product._id}`}>
        <Card.Img src={product.image} variant="top" />
      </Link>
      <Card.Body>
        <Link to={`/product/${product._id}`}>
          <Card.Title as="h5" className='cardText'>
            <strong>{product.name}</strong>
          </Card.Title>
        </Link>
        <Card.Text  as="div" className='hidden'>
          <Rating 
          value={product.rating} 
          text = {`${product.numReviews} reviews`}/>
        </Card.Text>
        <Card.Text as="h3" style={{color: '#ad0303'}} >
        
         {/*{product.countInStock >0 ? "$"+product.price : "out of stock"} */} 
         ${product.price}
      
        </Card.Text>
      </Card.Body>
    </Card>
  )
}

export default Product