import React from 'react'
import {Container ,Row,Col } from 'react-bootstrap'
import facebook from '../assets/facebook.png';
import instargram from '../assets/instargram.png';
import telegram from '../assets/telegram.png';
import admin from '../assets/admin.png';
import { Navbar } from 'react-bootstrap';


const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer>
      <Container>
        <Row>
          <Col className='text-center py-3'>
          <a href="https://t.me/CyberBoard168" target="_blank" rel="noopener noreferrer"> 
            <Navbar.Brand className='tektur700 logoColor'>
              <img src={admin} alt='CYBERBOARD' style={{ height: "50px", width: "50px" , paddingRight: '10px'}} />       
                CyberBoardOwner @copyRight{currentYear}                 
            </Navbar.Brand>
          </a>
          </Col>
          <Col className='text-center py-3'>
          <a href="https://t.me/+WwoNYYBJOqs1YzFl" target="_blank" rel="noopener noreferrer"> 
            <Navbar.Brand className='tektur700 logoColor'>
              <img src={telegram} alt='CYBERBOARD' style={{ height: "50px", width: "50px" , paddingRight: '10px'}} />       
                Telegram Channel                 
            </Navbar.Brand>
          </a>
          </Col>
          <Col className='text-center py-3'>
          <a href="https://www.instagram.com/cyberboard168" target="_blank" rel="noopener noreferrer"> 
            <Navbar.Brand className='tektur700 logoColor'>
              <img src={instargram} alt='CYBERBOARD' style={{ height: "50px", width: "50px" , paddingRight: '10px'}} />       
                 Instargram                
            </Navbar.Brand>
          </a>
          </Col>
          <Col className='text-center py-3'>
          <a href="https://www.facebook.com/profile.php?id=61559906614645&mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer"> 
            <Navbar.Brand className='tektur700 logoColor'>
              <img src={facebook} alt='CYBERBOARD' style={{ height: "50px", width: "50px" , paddingRight: '10px'}} />       
                CyberBoard Page                  
            </Navbar.Brand>
          </a>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}

export default Footer